@font-face {
  font-family: 'anwalt-de';
  src: url('./fonts/anwalt-de.woff2?1or6p') format('woff2'), url('./fonts/anwalt-de.ttf?1or6p') format('truetype'),
    url('./fonts/anwalt-de.woff?1or6p') format('woff'), url('./fonts/anwalt-de.svg?1or6p#anwalt-de') format('svg');
  font-weight: normal;
  font-style: normal;
}

.anwalt-de {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'anwalt-de' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-akteneinsicht:before {
  content: '\108';
}
.ic-appointment:before {
  content: '\63';
}
.ic-articles:before {
  content: '\64';
}
.ic-articles-by-profile:before {
  content: '\4e';
}
.ic-blood-alcohol-calculator:before {
  content: '\59';
}
.ic-calculators:before {
  content: '\61';
}
.ic-child-support:before {
  content: '\70';
}
.ic-desktop:before {
  content: '\74';
}
.ic-curious-articles:before {
  content: '\53';
}
.ic-divorce-costs:before {
  content: '\69';
}
.ic-handshake:before {
  content: '\100';
}
.ic-hourglass-finshed:before {
  content: '\106';
}
.ic-imprint-generator:before {
  content: '\51';
}
.ic-latest-articles:before {
  content: '\42';
}
.ic-lawyers:before {
  content: '\43';
}
.ic-lawyer-search:before {
  content: '\45';
}
.ic-lawyersfee-calculator:before {
  content: '\44';
}
.ic-member-seal-logo:before {
  content: '\55';
}
.ic-mobile:before {
  content: '\101';
}
.ic-inotebook:before {
  content: '\102';
}
.ic-notepad:before {
  content: '\103';
}
.ic-profile-products:before {
  content: '\62';
}
.ic-order-given:before {
  content: '\105';
}
.ic-penalty-calculator:before {
  content: '\46';
}
.ic-profile:before {
  content: '\4b';
}
.ic-profile-visits:before {
  content: '\73';
}
.ic-ratings-by-profile:before {
  content: '\4c';
}
.ic-real-estate-calculator:before {
  content: '\4f';
}
.ic-received-messages:before {
  content: '\72';
}
.ic-red-traffic-light:before {
  content: '\71';
}
.ic-richter:before {
  content: '\104';
}
.ic-salary-calculator:before {
  content: '\49';
}
.ic-service:before {
  content: '\75';
}
.ic-severance-calculator:before {
  content: '\41';
}
.ic-smiling:before {
  content: '\79';
}
.ic-star:before {
  content: '\77';
}
.ic-success:before {
  content: '\76';
}
.ic-template-disclaimer:before {
  content: '\56';
}
.ic-template-privacy:before {
  content: '\47';
}
.ic-templates:before {
  content: '\57';
}
.ic-terminate-contract:before {
  content: '\68';
}
.ic-termination-effectiveness:before {
  content: '\67';
}
.ic-terminsvertretung:before {
  content: '\107';
}
.ic-tools:before {
  content: '\65';
}
.ic-veranstaltungen:before {
  content: '\111';
}
.ic-widget:before {
  content: '\50';
}
.ic-widget-ii:before {
  content: '\78';
}
.ic-back:before {
  content: '\112';
}
.ic-paperclip:before {
  content: '\113';
}
.ic-phone:before {
  content: '\114';
}
.ic-search:before {
  content: '\115';
}
.ic-save-open:before {
  content: '\116';
}
.ic-letter:before {
  content: '\117';
}
.ic-send:before {
  content: '\120';
}
.ic-anwaelte-profile-User-M:before {
  content: '\121';
}
.ic-anwaelte-profile-User-W:before {
  content: '\122';
}
.ic-profile-products-light:before {
  content: '\123';
}
.ic-Minijob-Rechner:before {
  content: '\124';
}
.ic-Mietbreisbemse-Rechner:before {
  content: '\125';
}
.ic-Immobilienkauf-Nebenkostenrechner:before {
  content: '\126';
}
.ic-Gehaltsrechner:before {
  content: '\127';
}
.ic-Abfindungsrechner:before {
  content: '\128';
}
.ic-lawyers-light:before {
  content: '\129';
}

.website_tool_type_imprint:before {
  content: '\51';
}

.website_tool_type_privacy:before {
  content: '\47';
}

.website_tool_type_disclaimer:before {
  content: '\56';
}

.abfindungsrechner:before,
.online_calculator_type_severance:before,
.widget_type_severance:before {
  content: '\41';
}

.anwaltskostenrechner:before,
.online_calculator_type_lawyers_fee:before {
  content: '\44';
}

.bussgeldrechner:before,
.online_calculator_type_penalty:before,
.widget_type_penalty:before {
  content: '\46';
}

.gehaltsrechner:before,
.online_calculator_type_salary:before {
  content: '\49';
}

.immobiliennebenkosten:before,
.online_calculator_type_real_estate:before {
  content: '\4f';
}

.promillerechner:before,
.online_calculator_type_blood_alc:before {
  content: '\59';
}

.widget_type_articles_newest:before {
  content: '\42';
}

.widget_type_search:before,
.widget_type_search_city:before,
.widget_type_search_topic:before {
  content: '\45';
}

.widget_type_profile:before {
  content: '\4b';
}

.widget_type_ratings_by_profile:before,
.widget_type_latest_ratings:before {
  content: '\4c';
}

.widget_type_profile_products_by_profile:before {
  content: '\62';
}

.widget_type_articles_by_profile:before {
  content: '\4e';
}

.widget_type_membership_seal_logo:before {
  content: '\55';
}
